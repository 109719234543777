'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
// tsconfig.json の esModuleInterop がtrueであれば以下の記述も可能
// import * as $ from 'jquery';
// import $ = require('jquery');
/*
let user = new Model.Person("Mike2");

$(() => {
  $("#name").html(user.getName());
});
*/
$(function () {
    // 登場人物
    var 
    // 基本要素
    ui_box = $('.js-ui-box') // UIボックス
    , sets_ctr = $('.js-setting-container') // セッティングコンテナ
    , sets_tit = $('.js-setting-title') // セッティングコンテナのタイトル
    , sets_val = $('.js-settings') // セッティング値
    , btn_ctr = $('.js-btn-container') // ボタンコンテナ
    , ipt_files_start = $('.js-btn-file-up') // フォント選択ボタン
    , ipt_files_delete = $('.js-btn-file-delete') // フォント削除ボタン
    , font_ctr = $('.js-font-container') // フォントコンテナ
    , card_wrappr = $('.js-card-wrapper') // カードラッパー
    // モーダル関連
    , modal_btns = $('[name="modal-btns"]') // モーダルボタン
    , modal_window = $('.js-modal-overlay') // モーダルウインドウ
    // フォントスタイル関連
    , opt_format = '' // @font-faceのformat用
    , opt_filetype = '' // フォントのファイルタイプ用
    , opt_class_ft = '' // フォントタイプを示すclass名用
    , opt_text_contents = '' // 例文テキスト保管用
    // ダークモード関連
    , ipt_font_color = $('.js-ipt-font-color') // 文字色インプット
    , opt_font_color = $('.js-opt-font-color') // 文字色アウトプット
    , change_count = 0 // 文字色の変化を監視する変数
    , mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)'); // ダークモード判定変数
    ;
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    // セッティングコンテナ開閉処理
    sets_tit.on('click', function () {
        $(this).toggleClass('is-close');
    });
    // セッティング値の変更敷衍処理
    sets_val.on('input', function () {
        var thisVal = $(this).val();
        var font_card_ta = $('.js-sample-text');
        switch ($(this).attr('id')) {
            case 'ipt_font-size':
                font_card_ta.css('font-size', thisVal + "px");
                break;
            case 'ipt_font-weight':
                font_card_ta.css('font-weight', thisVal);
                break;
            case 'ipt_font-color':
                font_card_ta.css('color', thisVal);
                break;
            case 'ipt_sample-text':
                font_card_ta.val(thisVal);
                break;
            case 'ipt_dummy-text':
                font_card_ta.val(thisVal);
                break;
            default:
                break;
        }
        // 値をoutputに表示
        $(this).prev('output').text("" + thisVal);
    });
    // textareaの高さを連動させる処理
    $(document).on('mousemove', '.js-sample-text', function () {
        // mouseup mousedown mouseleave mouseout mousemove
        var ta_h = $(this).css('height');
        $('.js-sample-text').css('height', ta_h);
    });
    // モーダルオーバーレイ表示処理
    modal_btns.on('change', function () {
        modal_window.toggle();
    });
    // ユーザーが文字色を変更したかを監視する処理
    ipt_font_color.on('input change', function () {
        change_count += 1;
    });
    // ダークモードに変化した場合の処理
    mediaQueryList.addEventListener('change', function () {
        darkStyleChange();
    });
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    // 最初のファイル選択時にヘッダーなどのスタイルを変更する関数
    function style_change() {
        // セッティングコンテナとフォントコンテナを表示
        sets_ctr.slideDown(700);
        font_ctr.slideDown(700);
        // 特定の要素のスタイルを変更
        ui_box.addClass('is-fileUp');
        // ボタンコンテナを非表示
        btn_ctr.hide();
    }
    // モードを切り替えた時に文字色の値を切り替える関数
    function darkStyleChange() {
        // ※但し、ユーザーが値を変更していた場合を除く
        if (change_count === 0) {
            switch (mediaQueryList.matches) {
                case true:
                    ipt_font_color.val('#d9d9d9');
                    opt_font_color.text('#d9d9d9');
                    $('.js-sample-text').css('color', '#d9d9d9');
                    break;
                case false:
                    ipt_font_color.val('#333333');
                    opt_font_color.text('#333333');
                    $('.js-sample-text').css('color', '#333333');
                    break;
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    // 選択されたファイルに対して処理する関数
    function file_encode(upfiles) {
        // セッティングの値を取得
        var ipt_font_size = $('.js-ipt-font-size').val(), ipt_font_weight = $('.js-ipt-font-weight').val(), ipt_font_color = $('.js-ipt-font-color').val(), ipt_dummy_text = $('.js-ipt-dummy-text').val(), ipt_sample_text = $('.js-ipt-sample-text').val();
        // // フォントカードに反映する例文テキストの確定処理
        if (ipt_sample_text === '') {
            // サンプルテキストが空の場合
            if (ipt_dummy_text === '') {
                // ダミーテキストも空の場合、定型例文を代入
                opt_text_contents = 'あいうえお　アイウエオ　ＡＢＣＤＥ　ａｂｃｄｅ　漢字　0123456789　０１２３４５６７８９　ABCDEFGHIJKLMNOPQRSTUVWXYZ　abcdefghijklmnopqrstuvwxyz';
            }
            else {
                // ダミーテキストが選択されている場合、これを代入
                opt_text_contents = ipt_dummy_text;
            }
        }
        else {
            // サンプルテキストが入力されている場合、これを代入
            opt_text_contents = ipt_sample_text;
        }
        // 選択されたフォントファイルが1個以上なら...
        if (upfiles.files.length > 0) {
            var _loop_1 = function (i) {
                var file = upfiles.files[i];
                // readerのresultプロパティに、データURLとしてエンコードされたファイルデータを格納
                var reader = new FileReader();
                reader.readAsDataURL(file);
                // readerが読み込まれたら...
                reader.onload = function () {
                    var upload_files = file.name.split('.'); // アップロードされたファイル
                    var file_name = upload_files[0]; // ファイル名
                    var file_type = upload_files[1]; // 拡張子
                    var file_full_name = file_name + "." + file_type; // ファイル名+拡張子
                    var opt_class_name = i + "-" + file_name + "-" + file_type + "-" + Date.now();
                    // フォントファイルの拡張子ごとに処理...
                    switch (file_type) {
                        // TrueTypeの場合...
                        case 'ttf':
                            opt_class_ft = 'ft-ttf';
                            opt_format = 'truetype';
                            opt_filetype = 'TrueType';
                            break;
                        // OpenTypeの場合...
                        case 'otf':
                            opt_class_ft = 'ft-otf';
                            opt_format = 'opentype';
                            opt_filetype = 'OpenType';
                            break;
                        // Embedded OpenTypeの場合...
                        case 'eot':
                            opt_class_ft = 'ft-eot';
                            opt_format = 'embedded-opentype';
                            opt_filetype = 'Embedded OpenType';
                            break;
                        // Scalable Vector Graphicsの場合...
                        case 'svg':
                            opt_class_ft = 'ft-svg';
                            opt_format = 'svg';
                            opt_filetype = 'Scalable Vector Graphics';
                            break;
                        // Web Open Font Formatの場合...
                        case 'woff':
                            opt_class_ft = 'ft-woff';
                            opt_format = 'woff';
                            opt_filetype = 'Web Open Font Format';
                            break;
                        // Web Open Font Format 2の場合...
                        case 'woff2':
                            opt_class_ft = 'ft-woff2';
                            opt_format = 'woff2';
                            opt_filetype = 'Web Open Font Format 2';
                            break;
                        // 上記以外の場合...
                        default:
                            opt_format = '';
                            break;
                    }
                    // フォントカード装飾用HTML
                    var font_style = "\n            <style>\n              @font-face {\n                font-family: \"" + file_full_name + "\";\n                src: url(\"" + reader.result + "\") format(\"" + opt_format + "\");\n              }\n              .js-sample-text-" + opt_class_name + " {\n                font-family: \"" + file_full_name + "\";\n                font-size: " + ipt_font_size + "px;\n                font-weight: " + ipt_font_weight + ";\n                color: " + ipt_font_color + ";\n              }\n            </style>\n          ";
                    // フォントカード用HTML
                    var font_card = "\n            <div class=\"fontCard\">\n              <div class=\"fontCard__head\">\n                <h2>\n                  <input class=\"js-font-name-" + i + "\" type=\"text\" value=\"" + file_full_name + "\">\n                </h2>\n                <p>\n                  <span class=\"js-file-name\">" + file_full_name + "</span>\n                  <span>/</span>\n                  <span class=\"js-file-type " + opt_class_ft + "\">" + opt_filetype + "</span>\n                </p>\n              </div>\n              <textarea class=\"js-sample-text js-sample-text-" + opt_class_name + "\" placeholder=\"\">" + opt_text_contents + "</textarea>\n            </div>\n          ";
                    // フォントスタイルをHTMLに追加
                    card_wrappr.append(font_style);
                    // フォントカードをHTMLに追加
                    card_wrappr.prepend(font_card);
                };
            };
            // フォントファイルの数だけ処理を繰り返す
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // スタート
            for (var i = 0; i < upfiles.files.length; i++) {
                _loop_1(i);
            }
            // エンド
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // ウインドウを閉じる場合...
            $(window).on('beforeunload', function (e) {
                // 確認ダイアログを表示
                var result = window.confirm('このページから移動すると表示状態が初期化されます。よろしいですか？');
                // 「OK」を選択した場合...
                if (result) {
                    // return confirm();
                    location.href = 'https://fs3.lowgs.com/';
                    return false;
                }
            });
        }
    }
    // ブラウザ標準のドロップ機能の無効化
    window.addEventListener('load', function () {
        // 標準の機能（開ければブラウザで開き、開けなければダウンロードするアレ）を無効化
        document.getElementById('droparea').addEventListener('dragover', function (evt) {
            evt.stopPropagation();
            evt.preventDefault();
            evt.dataTransfer.dropEffect = 'copy';
        });
    });
    // （スタート画面）もしフォントファイルが選択されたら...
    ipt_files_start.on('change', function () {
        // 各種スタイルを変更
        style_change();
        // ファイルエンコード
        file_encode(this);
        // 削除ボタンの非活性スタイルを除去
        ipt_files_delete.removeClass('is-invalid');
    });
    // （スタート画面）もしフォントファイルがドロップされたら...
    document.getElementById('droparea').addEventListener('drop', function (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        var files = evt.dataTransfer.files;
        console.log(files.length);
        console.log(files);
        style_change();
        var ipt_font_size = $('.js-ipt-font-size').val(), ipt_font_weight = $('.js-ipt-font-weight').val(), ipt_font_color = $('.js-ipt-font-color').val(), ipt_dummy_text = $('.js-ipt-dummy-text').val(), ipt_sample_text = $('.js-ipt-sample-text').val();
        // フォントカードに反映する例文テキストの確定処理
        if (ipt_sample_text === '') {
            // サンプルテキストが空の場合
            if (ipt_dummy_text === '') {
                // ダミーテキストも空の場合、定型例文を代入
                opt_text_contents = 'あいうえお　アイウエオ　ＡＢＣＤＥ　ａｂｃｄｅ　漢字　0123456789　０１２３４５６７８９　ABCDEFGHIJKLMNOPQRSTUVWXYZ　abcdefghijklmnopqrstuvwxyz';
            }
            else {
                // ダミーテキストが選択されている場合、これを代入
                opt_text_contents = ipt_dummy_text;
            }
        }
        else {
            // サンプルテキストが入力されている場合、これを代入
            opt_text_contents = ipt_sample_text;
        }
        var _loop_2 = function (i) {
            var file = files[i];
            // readerのresultプロパティに、データURLとしてエンコードされたファイルデータを格納
            var reader = new FileReader();
            reader.readAsDataURL(file);
            // readerが読み込まれたら...
            reader.onload = function () {
                var upload_files = file.name.split('.'); // アップロードされたファイル
                var file_name = upload_files[0]; // ファイル名
                var file_type = upload_files[1]; // 拡張子
                var file_full_name = file_name + "." + file_type; // ファイル名+拡張子
                var opt_class_name = i + "-" + file_name + "-" + file_type + "-" + Date.now();
                // フォントファイルの拡張子ごとに処理...
                switch (file_type) {
                    // TrueTypeの場合...
                    case 'ttf':
                        opt_class_ft = 'ft-ttf';
                        opt_format = 'truetype';
                        opt_filetype = 'TrueType';
                        break;
                    // OpenTypeの場合...
                    case 'otf':
                        opt_class_ft = 'ft-otf';
                        opt_format = 'opentype';
                        opt_filetype = 'OpenType';
                        break;
                    // Embedded OpenTypeの場合...
                    case 'eot':
                        opt_class_ft = 'ft-eot';
                        opt_format = 'embedded-opentype';
                        opt_filetype = 'Embedded OpenType';
                        break;
                    // Scalable Vector Graphicsの場合...
                    case 'svg':
                        opt_class_ft = 'ft-svg';
                        opt_format = 'svg';
                        opt_filetype = 'Scalable Vector Graphics';
                        break;
                    // Web Open Font Formatの場合...
                    case 'woff':
                        opt_class_ft = 'ft-woff';
                        opt_format = 'woff';
                        opt_filetype = 'Web Open Font Format';
                        break;
                    // Web Open Font Format 2の場合...
                    case 'woff2':
                        opt_class_ft = 'ft-woff2';
                        opt_format = 'woff2';
                        opt_filetype = 'Web Open Font Format 2';
                        break;
                    // 上記以外の場合...
                    default:
                        opt_format = '';
                        break;
                }
                // フォントカード装飾用HTML
                var font_style = "\n          <style>\n            @font-face {\n              font-family: \"" + file_full_name + "\";\n              src: url(\"" + reader.result + "\") format(\"" + opt_format + "\");\n            }\n            .js-sample-text-" + opt_class_name + " {\n              font-family: \"" + file_full_name + "\";\n              font-size: " + ipt_font_size + "px;\n              font-weight: " + ipt_font_weight + ";\n              color: " + ipt_font_color + ";\n            }\n          </style>\n        ";
                // フォントカード用HTML
                var font_card = "\n          <div class=\"fontCard\">\n            <div class=\"fontCard__head\">\n              <h2>\n                <input class=\"js-font-name-" + i + "\" type=\"text\" value=\"" + file_full_name + "\">\n              </h2>\n              <p>\n                <span class=\"js-file-name\">" + file_full_name + "</span>\n                <span>/</span>\n                <span class=\"js-file-type " + opt_class_ft + "\">" + opt_filetype + "</span>\n              </p>\n            </div>\n            <textarea class=\"js-sample-text js-sample-text-" + opt_class_name + "\" placeholder=\"\">" + opt_text_contents + "</textarea>\n          </div>\n        ";
                // フォントスタイルをHTMLに追加
                card_wrappr.append(font_style);
                // フォントカードをHTMLに追加
                card_wrappr.prepend(font_card);
            };
        };
        // console.log(files);
        for (var i = 0; i < files.length; i++) {
            _loop_2(i);
        }
    });
    // （すべて削除）もしフォントファイルが選択されたら...
    ipt_files_delete.on('click', function () {
        // 確認ダイアログを表示
        var result = window.confirm('すべてのフォントを削除します。削除してもいいですか？');
        // 「OK」を選択した場合...
        if (result) {
            // ファイルリストを初期化
            ipt_files_start.val('');
            // フォントカードを削除
            card_wrappr.empty();
            // 削除ボタンの非活性スタイルを追加
            ipt_files_delete.addClass('is-invalid');
        }
    });
});
